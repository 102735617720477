import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { colours } from "../../assets/colours";

import "../styles/componentStyles/ColumnGrid.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowPointer,
  faBolt,
  faCircleX,
  faShieldCheck,
} from "@fortawesome/pro-solid-svg-icons";

function ColumnGrid(props) {
  const { t } = useTranslation();
  const [values, setValues] = useState(props.data);
  const [severity, setSeverity] = useState({ text: "", color: "" });
  const [showLocations, setShowLocations] = useState(false);

  useEffect(() => {
    if (props.data && props.data[0].value.severity) {
      let value = Number(props.data[0].value.severity);
      if (value === 1) {
        setSeverity(() => ({ text: `${t("DW024")}`, color: colours.green }));
      } else if (value === 2) {
        setSeverity(() => ({ text: `${t("D218")}`, color: colours.yellow }));
      } else if (value === 3) {
        setSeverity(() => ({ text: `${t("D219")}`, color: colours.orange }));
      } else if (value === 4) {
        setSeverity(() => ({ text: `${t("D220")}`, color: colours.orange }));
      } else {
        setSeverity(() => ({ text: `${t("D221")}`, color: colours.red }));
      }
    }
  }, [props.data]);

  return (
    <div className={"chart-container " + props.width}>
      <div className="title-container">
        <div className="title">{props.title}</div>
      </div>
      <div className="grid-container">
        {values.map((item, index) => {
          return (
            <div className="column fs-mask" key={index}>
              <div className="row">
                <div className="row-heading">{t("D015")}</div>
                <div>{item.value.primary_pain_location}</div>
              </div>

              <div className="row">
                <div className="row-heading">{t("D016")}</div>
                <div style={{ color: colours["faded-rose"] }}>
                  {/* {showLocations && <div className="pain-tooltip"></div>}{" "} */}
                  <span className="icon_circle">
                    <FontAwesomeIcon icon={faArrowPointer} className="icon" />
                  </span>
                  {item.value.secondary_locations ? (
                    <div
                      className="locations"
                      onMouseEnter={() => setShowLocations(true)}
                      onMouseLeave={() => setShowLocations(false)}
                    >
                      {showLocations && (
                        <div className="pain-tooltip">
                          {item.value.secondary_locations.map(
                            (location, index) => (
                              <div key={index}>{location}</div>
                            )
                          )}
                        </div>
                      )}{" "}
                      {t("DW025")}
                    </div>
                  ) : (
                    <div> {t("D032")} </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="row-heading">{t("D017")}</div>
                {item.value.severity && Number(item.value.severity) > 0 ? (
                  <div style={{ color: `${severity.color}` }}>
                    <span className="icon_circle">
                      <FontAwesomeIcon icon={faBolt} className="icon" />
                    </span>
                    {severity.text}
                  </div>
                ) : (
                  <div>-</div>
                )}
              </div>
              <div className="row">
                <div className="row-heading">{t("D018")}</div>
                {item.value.specialist_support === "yes" &&
                  item.value.specialist_support !== "-" && (
                    <div style={{ color: colours.green }}>
                      <span className="icon_circle ">
                        <FontAwesomeIcon
                          icon={faShieldCheck}
                          className="icon"
                        />
                      </span>
                      {item.value.specialist_support}
                    </div>
                  )}
                {item.value.specialist_support === "no" &&
                  item.value.specialist_support !== "-" && (
                    <div style={{ color: colours.red }}>
                      <span className="icon_circle ">
                        <FontAwesomeIcon icon={faCircleX} className="icon" />
                      </span>
                      {item.value.specialist_support}
                    </div>
                  )}{" "}
              </div>

              <div className="row">
                <div className="row-heading">{t("D019")}</div>
                {item.value.activity_status === "Active" && (
                  <div style={{ color: colours.green }}>
                    <span className="icon_circle">
                      <FontAwesomeIcon icon={faShieldCheck} className="icon" />
                    </span>
                    {item.value.activity_status}
                  </div>
                )}

                {item.value.activity_status === "Inactive" && (
                  <div style={{ color: colours.red }}>
                    <span className="icon_circle ">
                      <FontAwesomeIcon icon={faCircleX} className="icon" />
                    </span>
                    {item.value.activity_status}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ColumnGrid;
