import "./style.css";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { handleEmailChange } from "../../shared/util/handleValueChange";

import Logo from "../../pages/images/logo_white.svg";
import Layout from "../components/Layout";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import PasswordInput from "../components/PasswordInput";
import Passcode from "../components/Passcode";

import GoogleAuthButton from "../../shared/components/logIn/singleSignOn/GoogleAuthButton";
import MicrosoftAuthButton from "../../shared/components/logIn/singleSignOn/MicrosoftAuthButton";
import OpenIDAuthButton from "../../shared/components/logIn/singleSignOn/OpenIDAuthButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";

function Login(props) {
  const location = useLocation();
  const { t } = useTranslation();

  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest } = useHttpClient();

  const [isCompLoading, setCompLoading] = useState(false);
  const [errorCompMessage, setCompErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [userId, setUserId] = useState();
  const [name, setName] = useState();
  const [clientId, setClientId] = useState(false);
  const [showSignUp, setShowSignUp] = useState(true);
  const [isStandardAuthActive, setIsStandardAuthActive] = useState(false);
  const [isMicrosoftAuthActive, setIsMicrosoftAuthActive] = useState(false);
  const [isGoogleAuthActive, setIsGoogleAuthActive] = useState(false);
  const [singleSignOn, setSingleSignOn] = useState([]);
  const [tempAccessToken, setTempAccessToken] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setCompErrorMessage(error);
  }, [error]);

  useEffect(() => {
    if (!auth.isLoggedIn && !location.state) {
      let data = JSON.parse(localStorage.getItem("userData"));
      if (data) {
        setIsStandardAuthActive(data.isStandardAuthActive);
        setIsMicrosoftAuthActive(data.isMicrosoftAuthActive);
        setIsGoogleAuthActive(data.isGoogleAuthActive);
        setSingleSignOn(data.singleSignOn);
        setClientId(data.clientid);
        if (data.fandfUser === "fandfch") {
          setShowSignUp(false);
        }
      } else {
        navigate("/");
        return;
      }
    }
  }, [auth.isLoggedIn, location, navigate, props]);

  const signInHandler = async (event) => {
    event.preventDefault();
    try {
      let response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/signin`,
        "POST",
        JSON.stringify({
          email: email.toLowerCase(),
          password: password,
          platform: "web",
        })
      );
      if (response && response.user._id) {
        setUserId(response.user._id);
        setName(response.user.name);

        setTempAccessToken(response.tempToken);
        if (!response.user.name || !response.user.passcode) {
          response.user.email = email;
          response.user.password = password;
          navigate("/signup", {
            state: response,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleResponseSSO = async (response) => {
    if (response) {
      setUserId(response.user._id);
      setName(response.user.name);

      if (
        (response.user.completed_registration === undefined ||
          response.user.completed_registration === true) &&
        response.token
      ) {
        await auth.login(
          response.user._id,
          response.token,
          response.user.name,
          response?.expirationDate,
          response.user.access,
          response.user.image,
          response.user.tags,
          response.clientName,
          response.user.nps_status,
          response.user.auth_type,
          response.user.email,
          response.user.language
        );

        //check if assessment complete or redirected from another page
        let storedUrl = sessionStorage.getItem("DirectLink");
        sessionStorage.removeItem("DirectLink");
        if (storedUrl && response.user.tags.length > 1) {
          navigate(storedUrl);
        } else if (response.user.tags.length > 1) {
          navigate("/for-you");
        } else {
          navigate("/assessment");
        }
      } else {
        navigate("/signup", {
          state: response,
        });
      }
    }
  };

  const signInWithPasscode = async (code) => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/checkPasscode`,
        "POST",
        JSON.stringify({
          tempToken: tempAccessToken,
          passcode: code,
          deviceLanguage: localStorage.getItem("deviceLanguage") || "",
          platform: "web",
        })
      );

      if (response && response.token) {
        await auth.login(
          response.user._id,
          response.token,
          response.user.name,
          response?.expirationDate,
          response.user.access,
          response.user.image,
          response.user.tags,
          response.clientName,
          response.user.nps_status,
          response.user.auth_type,
          response.user.email,
          response.user.language
        );

        //check if assessment complete
        let storedUrl = sessionStorage.getItem("DirectLink");
        sessionStorage.removeItem("DirectLink");

        if (storedUrl) {
          navigate(storedUrl);
        } else if (response.user.tags.length > 1) {
          navigate("/for-you");
        } else {
          navigate("/assessment");
        }
      }
    } catch (err) {
      setErrorMessage(err);
    }
  };

  return (
    <Layout>
      <div id="right_container">
        {!auth.isLoggedIn && (
          <div className="frame">
            <div className="logo">
              <img
                src={Logo}
                className="icon title_icon"
                alt="Champion Health Logo"
              />
            </div>
            <h1>{t("R011")}</h1>
            {!auth.isLoggedIn && !userId && isStandardAuthActive && (
              <React.Fragment>
                <form>
                  <input
                    type="email"
                    className={"code_input"}
                    id="email"
                    name="email"
                    placeholder={t("R023")}
                    onChange={(e) => handleEmailChange(e, setEmail)}
                  />
                  <PasswordInput
                    visible={true}
                    handlePassword={(value) => setPassword(value)}
                  />
                  {errorCompMessage && (
                    <p className="login_error">
                      {" "}
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="icon"
                      />
                      <span
                        dangerouslySetInnerHTML={{ __html: errorCompMessage }}
                      />
                    </p>
                  )}
                </form>

                {isLoading && isCompLoading && <LoadingSpinner asOverlay />}
                {(!isLoading || !isCompLoading) && (
                  <button onClick={signInHandler} id="continue">
                    {t("R011")}
                  </button>
                )}

                {isStandardAuthActive && (
                  <a
                    style={{
                      margin: "10px 0px 0px 0px",
                      fontFamily: "futura-pt-book",
                    }}
                    className="signin"
                    href="/reset"
                  >
                    {name ? t("R047") : t("R014")}
                  </a>
                )}

                {(!isLoading || !isCompLoading) &&
                  isStandardAuthActive &&
                  (isMicrosoftAuthActive ||
                    isGoogleAuthActive ||
                    singleSignOn.length > 0) && (
                    <p className="or">{t("R071")}</p>
                  )}
              </React.Fragment>
            )}
            {!auth.isLoggedIn && userId && name && (
              <React.Fragment>
                <p>{t("R043")}</p>
                <Passcode
                  handleContinue={signInWithPasscode}
                  errorMessage={errorMessage}
                />
              </React.Fragment>
            )}
            {(!isLoading || !isCompLoading) &&
              isMicrosoftAuthActive &&
              !userId && (
                <MicrosoftAuthButton
                  handleResponse={handleResponseSSO}
                  fandfUser={props.fandfUser}
                  clientid={clientId}
                  setErrorMessage={setCompErrorMessage}
                  setCompLoading={setCompLoading}
                  signIn={true}
                ></MicrosoftAuthButton>
              )}
            {(!isLoading || !isCompLoading) &&
              isGoogleAuthActive &&
              !userId && (
                <GoogleAuthButton
                  handleResponse={handleResponseSSO}
                  fandfUser={props.fandfUser}
                  clientid={clientId}
                  setErrorMessage={setCompErrorMessage}
                  setCompLoading={setCompLoading}
                ></GoogleAuthButton>
              )}

            {singleSignOn?.map((sso) => {
              if ((!isLoading || !isCompLoading) && !userId) {
                return (
                  <OpenIDAuthButton
                    key={sso._id}
                    configurationName={sso.type}
                    ssoId={sso.id}
                    redirectUri={`${window.location.origin}/sign-in/callback`}
                    scope={sso.scope}
                    authority={sso.issuer}
                    secret={sso.secret}
                    clientId={clientId}
                    setErrorMessage={setCompErrorMessage}
                    handleResponse={handleResponseSSO}
                  />
                );
              }

              return null;
            })}
          </div>
        )}

        <div className="lower_container bottom_padding">
          {isStandardAuthActive &&
            !auth.isLoggedIn &&
            !userId &&
            showSignUp && (
              <p className="signin">
                {t("R050")} <a href="/signup">{t("R018")}</a>
              </p>
            )}
          <p className="signin">
            {t("R075")}{" "}
            <a
              href="https://support.championhealth.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              {t("R076")}
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
}
export default Login;
