import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightToBracket,
  faBell,
  faChevronRight,
  faLockAlt,
  faStar,
  faTrashAlt,
  faUser,
  faLanguage,
} from "@fortawesome/pro-light-svg-icons";

import { colours } from "../../../assets/colours";
import ImageUploadBtn from "./ImageUploadBtn";
import { AuthContext } from "../../../shared/context/auth-context";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";

const AccountDetailsLeftMenu = (props) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const today = new Date();
  const currentYear = today.getFullYear();

  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {props.profile && (
        <>
          <div className="profile-photo fs-exclude">
            {isLoading && <LoadingSpinner asImage />}
            <img
              id="preview"
              key={auth.imageKey}
              src={auth.image}
              alt="profile"
              onLoad={() => setIsLoading(false)}
              style={{ display: isLoading ? "none" : "block" }}
            />
            <ImageUploadBtn saveChanges={props.saveChanges} />
          </div>
          <div className="profile-name fs-exclude">{props.profile.name}</div>
          <div
            className={`profile-slider-btn ${
              props.activeSubMenu.id === 51 && !props.hide ? "active" : ""
            }`}
            onClick={() => props.changeSubMenu(0)}
          >
            <div className="menu-item">
              <FontAwesomeIcon
                icon={faUser}
                style={{ color: colours.blue }}
                className="slider-icon"
              />

              <div className="slider-text">{t("S025")}</div>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className="slider-chevron" />
          </div>
          {(auth.authType == null || auth.authType === "ChampionHealth") && (
            <div
              className="profile-slider-btn"
              onClick={() => props.changeMenu(1)}
            >
              {" "}
              <div className="menu-item">
                <FontAwesomeIcon
                  icon={faLockAlt}
                  style={{ color: colours.yellow }}
                  className="slider-icon"
                />
                <div className="slider-text">{t("S126")}</div>
              </div>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="slider-chevron"
              />
            </div>
          )}
          {/* <div
            className="profile-slider-btn"
            onClick={() => props.changeMenu(2)}
          >
            {" "}
            <div className="menu-item">
              <FontAwesomeIcon
                icon={faBell}
                style={{ color: colours.green }}
                className="slider-icon"
              />
              <div className="slider-text">{t("S009")}</div>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className="slider-chevron" />
          </div> */}
          {auth.access === "10" && (
            <div
              className={`profile-slider-btn ${
                props.activeSubMenu.id === 57 && !props.hide ? "active" : ""
              }`}
              onClick={() => props.changeSubMenu(6)}
            >
              {" "}
              <div className="menu-item">
                <FontAwesomeIcon
                  icon={faLanguage}
                  style={{ color: colours.green }}
                  className="slider-icon"
                />
                <div className="slider-text">{t("PL001")}</div>
              </div>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="slider-chevron"
              />
            </div>
          )}

          <a
            href={
              "https://physitrack.typeform.com/first-feedback#client=" +
              auth.clientName.replace(/ /, "_")
            }
            target="_blank"
            rel="noreferrer"
          >
            <div className="profile-btn top">
              {" "}
              <FontAwesomeIcon icon={faStar} className="smaller-icon" />
              <div className="slider-text">{t("S005")}</div>
            </div>
          </a>
          <div className="profile-btn" onClick={auth.logout}>
            <FontAwesomeIcon
              icon={faArrowRightToBracket}
              className="smaller-icon"
            />
            <div className="slider-text">{t("S006")}</div>
          </div>
          <div className="profile-btn" onClick={() => props.changeSubMenu(3)}>
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="smaller-icon"
              style={{ color: colours.red }}
            />
            <div className="slider-text">{t("S007")}</div>
          </div>
          <div className="profile-footer">
            <div className="privacy">&copy; {currentYear} Champion Health</div>
            <div className="privacy">
              {" "}
              <a
                href="https://championhealth.co.uk/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                {t("S127")}
              </a>
            </div>
            <div className="privacy">
              {" "}
              <a
                href="https://championhealth.co.uk/privacy-policy#cookies"
                target="_blank"
                rel="noreferrer"
              >
                {t("S024")}
              </a>
            </div>
            <div className="privacy">
              {" "}
              <a
                href="https://championhealth.co.uk/eula/"
                target="_blank"
                rel="noreferrer"
              >
                {t("S131")}
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AccountDetailsLeftMenu;
