import i18n from "i18next";

export const pages = [
  { id: "foryou", url: "for-you", title: i18n.t("C001"), target: "" },
  { id: "sleep", url: "sleep-&-rest", title: i18n.t("C002") },
  { id: "movement", url: "movement", title: i18n.t("C003"), target: "" },
  { id: "mental", url: "mental-wellbeing", title: i18n.t("C004") },
  { id: "nutrition", url: "nutrition", title: i18n.t("C005") },
  {
    id: "hydration",
    url: "hydration-&-alcohol",
    title: i18n.t("C006"),
  },
  {
    id: "productivity",
    url: "work-&-productivity",
    title: i18n.t("C007"),
  },
  { id: "energy", url: "energy", title: i18n.t("C008") },
  { id: "physical", url: "physical-wellbeing", title: i18n.t("C009") },
  {
    id: "financial",
    url: "financial-wellbeing",
    title: i18n.t("C010"),
  },
];

export const haNavigation = [
  { target: 21, title: "recommended", code: "M080" },
  { target: 22, title: "results", code: "M081" },
  { target: 23, title: "track", code: "M082" },
  { target: 24, title: "challenge", code: "M083" },
];
