import React, { useEffect, useState } from "react";
import "../styles/componentStyles/ValueTile.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

const CircleValueTile = (props) => {
  const { t } = useTranslation();
  const [dataAvailable, setDataAvailable] = useState(true);

  useEffect(() => {
    if (props.data === null) {
      setDataAvailable(false);
    }
  }, [props.date]);
  return (
    <div className={"chart-container tile " + props.width}>
      <div className="title-container">
        <div className="title fs-exclude">{props.title}</div>
        {props.modalText && (
          <div className="wtf">
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="wtf icon"
              onClick={() => props.openModal(props.title, props.modalText)}
            />
          </div>
        )}
      </div>
      <div className="chart-box">
        <div className="circle_chart fs-exclude">
          {!props.withIcon && (
            <>
              <h3 style={{ color: props.colour }}>
                {props.textValue}
                {props.units && (
                  <span style={{ color: props.colour }}>{props.units}</span>
                )}
              </h3>
              <div className="value-descr">
                {props.maxValue
                  ? `${props.value} out of ${props.maxValue}`
                  : `${props.value}`}
              </div>
            </>
          )}
          {props.withIcon && dataAvailable && (
            <>
              <FontAwesomeIcon
                style={{ color: props.colour }}
                icon={props.icon}
                className=" icon tile"
              />
              <h3 style={{ color: props.colour }}>{props.data}</h3>
              <div style={{ color: props.colour }} className="value-descr caps">
                {props.value}
              </div>
            </>
          )}
          {props.withIcon && !dataAvailable && (
            <div className="chart-no-data nd-tile">{t("D075")}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CircleValueTile;
