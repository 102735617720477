import { useContext, useState } from "react";
import ChevronBackBtn from "../../../shared/components/styledComponents/chevronBackBtn/ChevronBackBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faLockAlt } from "@fortawesome/pro-light-svg-icons";
import PinkButton1 from "../../../shared/components/styledComponents/pinkButton1/PinkButton1";
import { AuthContext } from "../../../shared/context/auth-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import GreenCheckOverlay from "../../../shared/modal/greenCheckOverlay/GreenCheckOverlay";
import { useTranslation } from "react-i18next";
import CustomDropdown from "./CustomDropDown";

const RemoveAccountRightMenu = (props) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [selectedReason, setSelectedReason] = useState();
  const [id, setID] = useState();
  const [explanation, setExplanation] = useState();
  const [userText, setUserText] = useState("");
  const [showSecondScreen, setShowSecondScreen] = useState(false);

  const [showGreenCheck, setShowGreenCheck] = useState(false);

  const showGreenCheckOverlay = () => {
    setShowGreenCheck(true);
    setTimeout(() => {
      setShowGreenCheck(false);
    }, 2000);
  };

  const handleSelectReason = (reason) => {
    setSelectedReason(reason);
    // get index of selected reason
    let index = reasonsArr?.findIndex((x) => x.reason === reason);

    setID(reasonsArr[index].id);
    setExplanation(reasonsArr[index].explanation);

    if (index !== reasonsArr.length) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const [disabled, setDisabled] = useState(true);

  const handleTextAreaChange = (event) => {
    if (event.target.value.length > 3) {
      setDisabled(false);
    }
    setUserText(event.target.value);
  };

  const handleNextScreen = () => {
    setShowSecondScreen(true);
  };

  const handleRemoveAccount = async () => {
    try {
      let reason = id.toLowerCase() === "other" ? `Other | ${userText}` : id;

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/deleteuserdata`,
        "DELETE",
        JSON.stringify({ reason: reason, platform: "web" })
      );

      if (response) {
        showGreenCheckOverlay();
        setTimeout(() => {
          auth.logout();
        }, 2000);
      }
    } catch (err) {}
  };

  const reasonsArr = [
    {
      id: "problems_with_app",
      reason: t("S070"),
      explanation: [
        <p>
          <strong>{t("S071")}</strong>
        </p>,
        <p>
          {" "}
          <FontAwesomeIcon icon={faChevronRight} /> {t("S072")}{" "}
          <a
            href="https://championhealth.co.uk/help-and-support/technical-support/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("S073")}
          </a>
        </p>,
        <p>
          {" "}
          <FontAwesomeIcon icon={faChevronRight} /> {t("S074")}
        </p>,
        <p>
          {" "}
          <FontAwesomeIcon icon={faChevronRight} /> {t("S075")}{" "}
          <a
            href="https://championhealth.co.uk/help-and-support/frequently-asked-questions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("S076")}
          </a>
        </p>,
      ],
    },
    {
      id: "concerned_confidentiality",
      reason: t("S077"),
      explanation: [
        <p>
          <strong>{t("S078")}</strong>
        </p>,
        <p>
          {" "}
          <FontAwesomeIcon icon={faLockAlt} /> {t("S079")} <b> {t("S080")}</b>{" "}
          {t("S081")}
        </p>,
        <p>
          {" "}
          <FontAwesomeIcon icon={faLockAlt} /> {t("S082")}
          <b> {t("S083")}</b> {t("S084")}
        </p>,
        <p>
          <FontAwesomeIcon icon={faLockAlt} /> {t("S085")} <b> {t("S086")}</b>{" "}
          {t("S087")}
        </p>,
      ],
    },
    {
      id: "didnt_use_enough",
      reason: t("S088"),
      explanation: [
        <p>
          <strong>{t("S089")}</strong>
        </p>,
        <p>
          {" "}
          <FontAwesomeIcon icon={faChevronRight} /> {t("S090")}
        </p>,
        <p>
          {" "}
          <FontAwesomeIcon icon={faChevronRight} /> {t("S091")}
        </p>,
        <p>
          {" "}
          <FontAwesomeIcon icon={faChevronRight} /> {t("S092")}
        </p>,
      ],
    },
    {
      id: "content_not_useful",
      reason: t("S093"),
      explanation: [
        <p>
          <strong>{t("S094")}</strong>
        </p>,
        <p>{t("S095")}</p>,
        <p>
          {t("S096")}{" "}
          <a
            href="mailto:support@championhealth.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("S097")}
          </a>
        </p>,
      ],
    },
    {
      id: "didnt_meet_expectations",
      reason: t("S098"),
      explanation: [
        <p>
          <strong> {t("S099")}</strong>
        </p>,
        <p>{t("S100")}</p>,
        <p>
          {t("S101")}{" "}
          <a
            href={
              "https://physitrack.typeform.com/final-feedback#client=" +
              auth.clientName.replace(/ /, "_")
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("S102")}
          </a>
          )
        </p>,
      ],
    },
    {
      id: "too_many_health_apps",
      reason: t("S103"),
      explanation: [
        <p>
          <strong>{t("S104")}</strong>
        </p>,
        <p>
          {t("S105")}{" "}
          <a
            href={
              "https://physitrack.typeform.com/final-feedback#client=" +
              auth.clientName.replace(/ /, "_")
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("S102")}
          </a>
          )
        </p>,
      ],
    },
    {
      id: "prefer_another_app",
      reason: t("S106"),
      explanation: [
        <p>
          <strong>{t("S107")}</strong>
        </p>,
        <p>
          {t("S108")}{" "}
          <a
            href={
              "https://physitrack.typeform.com/final-feedback#client=" +
              auth.clientName.replace(/ /, "_")
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("S102")}
          </a>
          )
        </p>,
      ],
    },
    {
      id: "too_many_notifications",
      reason: t("S109"),
      explanation: [
        <p>
          <strong>{t("S110")}</strong>
        </p>,
        <p>
          {" "}
          <FontAwesomeIcon icon={faChevronRight} /> {t("S111")}
        </p>,
        <p>
          {" "}
          <FontAwesomeIcon icon={faChevronRight} /> {t("S112")}
        </p>,
        <p>
          {" "}
          <FontAwesomeIcon icon={faChevronRight} /> {t("S113")}
        </p>,
      ],
    },
    { id: "other", reason: t("S114"), explanation: [] },
  ];
  return (
    <div className="edit-field fs-exclude">
      {showGreenCheck && <GreenCheckOverlay />}
      {props.hide && (
        <div className="btn-cont ">
          <ChevronBackBtn onClick={() => props.changeMenu(0, "back")} />
        </div>
      )}

      {!showSecondScreen && (
        <>
          <div className="field-cont">
            <span className="field-descr">{t("S115")}</span>
            <div className="select-cont">
              <CustomDropdown
                defaultValue={"Select Reason"}
                options={reasonsArr?.map((x) => x.reason)}
                value={selectedReason}
                onChange={handleSelectReason}
              />
            </div>
          </div>
          <div className="explanation-cont">
            {selectedReason === "Other" && (
              <textarea
                type="text"
                required
                minLength={4}
                value={userText}
                onChange={handleTextAreaChange}
                placeholder={t("S117")}
              />
            )}
            {selectedReason &&
              explanation?.map((para, index) => {
                return <div key={index}>{para}</div>;
              })}
          </div>
          <PinkButton1
            disabled={disabled}
            className="wide marginTop30"
            onClick={handleNextScreen}
          >
            {t("S118")}
          </PinkButton1>
        </>
      )}
      {showSecondScreen && (
        <>
          <div>{t("S119")} </div>
          <div style={{ height: "30px" }}></div>
          <div>{t("S120")}</div>{" "}
          <PinkButton1
            className="wide marginTop30"
            onClick={handleRemoveAccount}
          >
            {t("S118")}
          </PinkButton1>
        </>
      )}
    </div>
  );
};
export default RemoveAccountRightMenu;
