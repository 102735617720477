import React, { useState, useCallback, useEffect } from "react";
import "./Style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons";

import CheckboxItem from "./CheckboxItem";

import OutsideAlerter from "../../../shared/hooks/OffClickClose";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

function Checkbox(props) {
  const [checkboxClass, setCheckboxClass] = useState("inactive");

  const [checkboxSelectorClass, setCheckboxSelectorClass] = useState("");

  const [upDownArrow, setupDownArrow] = useState(faChevronDown);

  const checkboxActiveHandler = useCallback(
    (props) => {
      if (checkboxClass === "inactive" && props.id !== "offclick") {
        setCheckboxClass("active");
        setupDownArrow(faChevronUp);
      } else {
        setCheckboxClass("inactive");
        setupDownArrow(faChevronDown);
      }
    },
    [checkboxClass]
  );

  const [selectedItems, setSelectedItems] = useState([]);

  const [plusItemsBox, setPlusItemsBox] = useState("");

  function addSelectedItemHandler(item) {
    checkPlusItem(item.add);

    if (item.add) {
      if (
        props.itemMax > 0 &&
        selectedItems.length === parseFloat(props.itemMax)
      ) {
        return;
      }

      if (selectedItems.length === 0) {
        setCheckboxSelectorClass("filled");
      }

      if (
        item.id === "none" ||
        item.id === "none_sq" ||
        item.id === "nothing" ||
        item.id === "none_nd"
      ) {
        //remove other options if 'none' selected
        item.class = "";
        saveValue([item]);
        if (props.questionid === "areas_to_improve") {
          props.onSetValue({ id: "main_area_to_improve", value: "" });
        }
        addPlusItem(1);
      } else {
        //remove none if it exists
        let newItems = selectedItems.concat(item);
        let items_without_none = newItems.filter(
          (kitem) =>
            kitem.id !== "none" &&
            kitem.id !== "none_sq" &&
            kitem.id !== "nothing" &&
            kitem.id !== "none_nd"
        );
        //save items list
        saveValue(items_without_none);
      }
    } else {
      // setSelectedItems(selectedItems.filter(kitem => kitem.id !== item.id));
      saveValue(selectedItems.filter((kitem) => kitem.id !== item.id));
      if (selectedItems.length === 1) {
        setCheckboxSelectorClass("");
      }
    }
  }

  function saveValue(newSelectedItems) {
    setSelectedItems(newSelectedItems);
    //behaviour change special rule
    if (props.questionid === "areas_to_improve") {
      // if(newSelectedItems.length > 0){
      if (
        newSelectedItems.length === 1 &&
        (newSelectedItems[0].id !== "none" ||
          newSelectedItems[0].id !== "none_sq" ||
          newSelectedItems[0].id !== "none_nd")
      ) {
        props.onSetValue({
          id: "main_area_to_improve",
          value: {
            id: newSelectedItems[0].id,
            title: newSelectedItems[0].title,
          },
        });
      } else if (newSelectedItems.length > 1 || newSelectedItems.length === 0) {
        props.onSetValue({ id: "main_area_to_improve", value: "" });
      }
      //}else{
      // props.onSetValue({id: 'main_area_to_improve', value: ''});
      // }
    }
    const valuesArr = [...newSelectedItems];
    valuesArr.map((item) => {
      delete item.add;
      delete item.class;
      return item;
    });
    props.onSetValue({ id: props.questionid, value: valuesArr });
  }

  function checkPlusItem(add) {
    var newItemCount = selectedItems.length;
    if (add) {
      newItemCount++;
    } else {
      newItemCount--;
    }
    if (!props.itemMax || newItemCount <= props.itemMax) {
      addPlusItem(newItemCount);
    }
  }

  function addPlusItem(newItemCount) {
    var id = props.questionid + "_plus_count";

    if (newItemCount > props.itemLimit) {
      var plus = newItemCount - props.itemLimit;
      setPlusItemsBox("+" + plus);
      document.getElementById(id).style.display = "inline-block";
    } else {
      document.getElementById(id).style.display = "none";
    }
  }

  useEffect(() => {
    if (props.assessmentValues[props.questionid]) {
      let values;
      if (Array.isArray(props.assessmentValues[props.questionid])) {
        values = props.assessmentValues[props.questionid];
      } else {
        values = [props.assessmentValues[props.questionid]];
      }
      values.map((value, index) => {
        if (index < props.itemLimit) {
          value.class = "";
        } else {
          value.class = "hidden";
        }
      });
      setSelectedItems(values);
      setCheckboxSelectorClass("filled");
      addPlusItem(props.assessmentValues[props.questionid].length);
    }
  }, [props.assessmentValues[props.questionid]]);

  const [blockOpacity, setBlockOpacity] = useState(0);

  useEffect(() => {
    setTimeout(function () {
      setBlockOpacity(1);
    }, 100);
  });

  /* EXCLUDE ONE ARRAY FROM THE OPTIONS */
  let optionslist = props.options;
  if (props.exclude) {
    optionslist = props.options.filter(
      (x) =>
        !props.exclude.filter((y) => y.id === x.id && x.id !== "other").length
    );
  }
  const options = optionslist;

  return (
    <div
      id={props.questionid}
      className={
        "question_block" + props.width + (props.skippable ? "" : " req")
      }
      style={{ opacity: props.fadeIn ? blockOpacity : "1" }}
    >
      <h3>{props.questionTitle}</h3>
      {props.questionTitleSuffix ? (
        <span className="titleSuffix">{props.questionTitleSuffix}</span>
      ) : null}
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
        />
      )}
      <div className="answer_block">
        <OutsideAlerter onOffClickDetected={checkboxActiveHandler}>
          <div className={"checkbox " + checkboxClass} id={props.questionid}>
            <FontAwesomeIcon
              icon={upDownArrow}
              className="icon drop_down_operator"
            />
            <div
              className={"checkbox_selector " + checkboxSelectorClass}
              onClick={checkboxActiveHandler}
            >
              <span id="please_select">Please select</span>
              {selectedItems.length > 0 &&
                selectedItems[0].class === "" &&
                selectedItems.map((item) => {
                  return (
                    <li
                      key={
                        props.secondaryQ
                          ? props.questionid + "_" + item.id + "_sq"
                          : props.questionid + "_" + item.id
                      }
                      className={item.class + " fs-exclude"}
                    >
                      {item.title.replace(/&amp;/gi, "&")}
                    </li>
                  );
                })}
              <span
                className="plus_count"
                id={props.questionid + "_plus_count"}
              >
                {plusItemsBox}
              </span>
            </div>
            <div className="checkbox_container fs-exclude">
              {options.map((option) => {
                let checked;
                {
                  selectedItems.find(
                    (element) =>
                      element.id === option.id ||
                      element.id === option.id + "_sq"
                  )
                    ? (checked = "checked")
                    : (checked = "");
                }
                return (
                  <CheckboxItem
                    key={props.questionid + "_" + option.id}
                    item={option}
                    onAddSelectedItem={addSelectedItemHandler}
                    checked={checked}
                    secondaryQ={props.secondaryQ}
                  />
                );
              })}
            </div>
          </div>
        </OutsideAlerter>
      </div>
    </div>
  );
}

export default Checkbox;
