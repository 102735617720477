import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import "../styles/CoverPageStyles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromSquare,
  faClosedCaptioning,
  faPlusCircle,
  faVolume,
} from "@fortawesome/pro-thin-svg-icons";

import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";

import { faPlusCircle as faPlusCircleSolid } from "@fortawesome/pro-solid-svg-icons";
import { faShieldHalved } from "@fortawesome/sharp-solid-svg-icons";
import { faUser } from "@fortawesome/pro-light-svg-icons";
import { faHighDefinition } from "@fortawesome/pro-thin-svg-icons";

import { AuthContext } from "../../shared/context/auth-context";
import { convertTime } from "../../shared/util/helperfunctions";

import CTA from "./CTA";
import Slider from "../../shared/components/slider/Slider";
import Recipe from "./Recipe";
import Workout from "./Workout";
import TakeAction from "../images/take-action.jpg";
import ReminderPopUp from "./ReminderPopUp";
import SharePopUp from "./SharePopUp";
import AuthorContainer from "./AuthorContainer";
import RateBlock from "./RateBlock";
import Footer from "../../shared/navfooter/Footer";
import {
  useUpdateFavouritesMutation,
  useUpdateLikesMutation,
} from "../../forYou/mediaSlidersApiSlice";

import ProgrammeBlock from "./ProgrammeBlock";

const ContentCover = (props) => {
  const { t } = useTranslation();
  const { mediaObject } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const auth = useContext(AuthContext);
  // const { t } = useTranslation();
  const [updateFavourites] = useUpdateFavouritesMutation();
  const [updateLikes] = useUpdateLikesMutation();

  const [favourite, setFavourite] = useState(false);
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [remindOpen, setRemindOpen] = useState(false);
  const [isService, setIsService] = useState(false);

  //programme
  const [activeDay, setActiveDay] = useState(false);

  // //scroll top on page load
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const mediaType = window.location.pathname.split("/")[1];

  //remember location for back button
  useEffect(() => {
    const previousPages =
      JSON.parse(sessionStorage.getItem("contentTypePreviousLocations")) || [];
    if (!previousPages[0] || previousPages[0] !== location.pathname)
      previousPages.unshift(location.pathname);
    sessionStorage.setItem(
      "contentTypePreviousLocations",
      JSON.stringify(previousPages)
    );
  }, [location.pathname]);

  useEffect(() => {
    if (mediaObject && mediaObject._id) {
      setFavourite(mediaObject.favourite);
      setLike(mediaObject.like);
      setDislike(mediaObject.dislike);
    }
  }, [mediaObject]);

  useEffect(() => {
    if (
      mediaObject?.category === "service" ||
      mediaObject?.chid.startsWith("TA")
    ) {
      setIsService(true);
    } else {
      setIsService(false);
    }
  }, [mediaObject?.category, mediaObject?.chid]);

  //scroll page back to top on load
  useEffect(() => {
    document
      .getElementById("cover-page")
      .scrollTo({ top: 0, behavior: "smooth" });
  }, [window.location.pathname]);

  const handleFavourite = () => {
    setFavourite(!favourite);
    let url = "/media";
    if (mediaType === "programme") {
      url = "/programme";
    } else if (mediaType === "masterclass") {
      url = "/masterclass";
    } else if (mediaType === "take-actions") {
      url = "/take-actions";
    }
    updateFavourites({
      token: auth.token,
      url: `${url}/favourites`,
      mediaId: mediaObject._id,
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleLikeDislike = (target) => {
    if (target === "like") {
      setLike(!like);
      setDislike(false);
    } else if (target === "dislike") {
      setDislike(!dislike);
      setLike(false);
    }

    let url = "/media";
    if (mediaType === "programme") {
      url = "/programme";
    } else if (mediaType === "masterclass") {
      url = "/masterclass";
    } else if (mediaType === "take-actions") {
      url = "/take-actions";
    }

    updateLikes({
      token: auth.token,
      url: `${url}/preference`,
      action: target,
      mediaId: mediaObject._id,
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      sessionStorage.setItem("pageReload", true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleNavigation = () => {
    const defaultPath = "/for-you";
    const reloaded = sessionStorage.getItem("pageReload");

    if (sessionStorage.length > 0) {
      const previousPages =
        JSON.parse(sessionStorage.getItem("contentTypePreviousLocations")) ||
        [];
      const previousLocation = JSON.parse(
        sessionStorage.getItem("previousLocation")
      );

      let targetPath;
      if (previousPages.length > 0) {
        targetPath = previousPages.shift();
      }

      // If the target path is the same as the current path, shift again
      if (targetPath === location.pathname) {
        targetPath = previousPages.shift();
      }
      // Update the previous pages in sessionStorage
      sessionStorage.setItem(
        "contentTypePreviousLocations",
        JSON.stringify(previousPages)
      );

      if (!targetPath) {
        targetPath = previousLocation?.pathname;
      }

      // Navigate to the target path if it exists, otherwise navigate to the default path
      targetPath
        ? reloaded
          ? navigate(targetPath)
          : navigate(-1)
        : navigate(defaultPath);
    } else {
      navigate(defaultPath);
    }
  };

  const processDescription = (description) => {
    //if not html, return description
    if (!description.startsWith("<")) {
      return description;
    } else {
      return (
        <div
          className="ta-html"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      );
    }
  };

  return (
    <React.Fragment>
      {mediaObject && mediaObject?.chid && (
        <>
          {remindOpen && <ReminderPopUp setRemindOpen={setRemindOpen} />}
          {shareOpen && <SharePopUp setShareOpen={setShareOpen} />}
          {mediaObject && (
            <div className="page-container">
              <div
                className="hero"
                style={{
                  backgroundImage: `url(${
                    mediaObject.chid.startsWith("TA")
                      ? TakeAction
                      : mediaObject.background_image
                      ? mediaObject.background_image
                      : mediaObject.video_cover_image
                      ? mediaObject.video_cover_image
                      : mediaObject.thumbnail_image
                  })`,
                }}
              >
                <div className="hero-contents cover">
                  <div
                    className="back-button"
                    onClick={() => handleNavigation()}
                  >
                    {" "}
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="icon return-arrow back"
                    />
                  </div>
                  <div className="splash-contents">
                    <div
                      className="title-cont fs-mask"
                      style={{
                        paddingBottom: isService ? "30px" : "",
                      }}
                    >
                      <h1>{mediaObject.title}</h1>
                    </div>

                    <div className="tag-line">
                      <p className="type">
                        {mediaObject.chid.startsWith("TA")
                          ? "TAKE ACTION"
                          : mediaObject.label}
                      </p>
                      {mediaObject.chid.startsWith("TA") && (
                        <FontAwesomeIcon
                          icon={faShieldHalved}
                          className="icon shield"
                        />
                      )}
                      {mediaObject.category === "service" && (
                        <FontAwesomeIcon
                          icon={faUser}
                          className="icon shield"
                        />
                      )}
                      {!isService && mediaObject.length && (
                        <p className="fs-mask">
                          {convertTime(mediaObject.length)}
                        </p>
                      )}
                      {mediaType === "programme" && (
                        <p className="fs-mask">
                          {mediaObject.days.length} days
                        </p>
                      )}
                      {mediaObject.type && !isService && (
                        <FontAwesomeIcon
                          icon={
                            mediaObject.type === "audio"
                              ? faVolume
                              : faHighDefinition
                          }
                          className="icon "
                        />
                      )}
                      {mediaObject.transcript && !isService && (
                        <FontAwesomeIcon
                          icon={faClosedCaptioning}
                          className="icon "
                        />
                      )}
                    </div>

                    <h3 className="fs-mask">
                      {processDescription(mediaObject.description)}
                    </h3>

                    {!mediaObject.chid.startsWith("TA") && (
                      <div className="action-line">
                        <div onClick={handleFavourite}>
                          {" "}
                          <FontAwesomeIcon
                            icon={favourite ? faPlusCircleSolid : faPlusCircle}
                            className="icon action"
                          />{" "}
                          <div className="list">
                            {favourite ? t("M004") : t("M005W")}
                          </div>
                        </div>

                        {!isService && (
                          <RateBlock
                            like={like}
                            dislike={dislike}
                            setLike={setLike}
                            setDislike={setDislike}
                            handleLikeDislike={handleLikeDislike}
                          />
                        )}

                        {!isService && (
                          <div
                            className="share-block"
                            onClick={() => setShareOpen(true)}
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={faArrowUpFromSquare}
                              className="icon action"
                            />{" "}
                            <div className="share">{t("M006")}</div>
                          </div>
                        )}

                        {/* <div
                    onClick={() => {
                      setRemindOpen(true);
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faBell} className="icon action" />
                    <div className="remind">{t("M007")}</div>
                  </div> */}
                      </div>
                    )}

                    <CTA
                      mediaType={mediaType}
                      mediaObject={mediaObject}
                      activeDay={activeDay}
                      setActiveDay={setActiveDay}
                    />
                  </div>
                </div>
                <div className="fade"></div>
              </div>

              {mediaObject.category === "recipe" && (
                <Recipe recipe={mediaObject} />
              )}
              {mediaObject.category === "workout" && (
                <Workout
                  exercises={mediaObject.exercises}
                  equipment={mediaObject.equipment}
                />
              )}
              {mediaType === "programme" && (
                <ProgrammeBlock programme={mediaObject} activeDay={activeDay} />
              )}
              {mediaObject.author?.author &&
                mediaObject.author?._id !== "645a407d2f5e5d00149daf9a" && (
                  <AuthorContainer author={mediaObject.author} />
                )}
              {mediaObject.related_media?.length > 0 && (
                <div
                  className={
                    (mediaObject.chid.startsWith("TA")
                      ? "negative-marg related"
                      : "related") + " fs-exclude"
                  }
                >
                  <Slider
                    title={
                      mediaObject.chid.startsWith("TA")
                        ? "Recommended for you"
                        : "Related titles"
                    }
                    media={mediaObject.related_media}
                  />
                </div>
              )}
              <Footer />
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
};
export default ContentCover;
