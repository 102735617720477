import { useEffect, useState, useContext, useCallback } from "react";
import PinkButton1 from "../../../shared/components/styledComponents/pinkButton1/PinkButton1";
import { validateUserInput } from "../../../shared/util/helperfunctions";
import { AuthContext } from "../../../shared/context/auth-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import WhitePopUpNoTitle from "../../../shared/modal/whitePopUp/WhitePopUpNoTitle";
import { useTranslation } from "react-i18next";
import GreenCheckOverlay from "../../../shared/modal/greenCheckOverlay/GreenCheckOverlay";
import ChevronBackBtn from "../../../shared/components/styledComponents/chevronBackBtn/ChevronBackBtn";
import CustomDropdown from "./CustomDropDown";

const AccountDetailsRightMenu = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTextContent, setModalTextContent] = useState();
  const [profile, setProfile] = useState(props.profile);
  const [activeDept, setActiveDept] = useState();
  const [activeSubDept, setActiveSubDept] = useState();
  const [subDeptsArr, setSubDeptsArr] = useState();
  const [activeRegion, setActiveRegion] = useState();
  const [regionArr, setRegionArr] = useState();
  const [regionGroupArr, setRegionGroupArr] = useState([]);
  const [regionGroup, setRegionGroup] = useState();
  const [regionsOptions, setRegionsOptions] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [showGreenCheck, setShowGreenCheck] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [activeButton, setActiveButton] = useState(true);

  const modalClickHandler = useCallback(() => {
    setModalIsOpen(false);
  }, [modalIsOpen]);

  const openModal = useCallback(
    (title, text) => {
      setModalIsOpen(true);
      setModalTextContent({ title: title, text: text });
    },
    [modalIsOpen, modalTextContent]
  );

  const showGreenCheckOverlay = () => {
    setShowGreenCheck(true);
    setTimeout(() => {
      setShowGreenCheck(false);
    }, 2000);
  };

  useEffect(() => {
    let userRegion;
    //exclude users without client regions or those with automatic region overwrite
    if (
      props.profile.regions?.length > 0 &&
      !(
        props.profile.access == "2" &&
        props.profile?.fandf_region_ovewrite === props.profile.user?.region
      )
    ) {
      //existing user region
      userRegion = props.profile.regions.find(
        (obj) => obj._id === props.profile.region
      );
      if (userRegion) {
        setActiveRegion(userRegion.verbose_name);
        if (userRegion.region_group) {
          setRegionGroup(userRegion.region_group);
        }
      }

      //all regions for this client
      let regions = [];

      props.profile.regions.map((region) => regions.push(region.verbose_name));
      setRegionArr(regions);
      setAllRegions(props.profile.regions);

      if (props.profile.regions[0].region_group) {
        let groupArr = [];
        props.profile.regions.map((region) => {
          if (!groupArr.includes(region.region_group)) {
            groupArr.push(region.region_group);
          }
        });
        let filteredOptionsArr = [];
        if (userRegion && userRegion.region_group) {
          filteredOptionsArr = props.profile.regions.filter(
            (region) => region.region_group === userRegion.region_group
          );
        }
        let reducedOptionsArr = [];
        filteredOptionsArr.map((region) =>
          reducedOptionsArr.push(region.verbose_name)
        );
        setRegionsOptions(reducedOptionsArr);
        setRegionGroupArr(groupArr);
      }
    }
    if (props.profile.departments?.length > 0 && props.profile.access != "2") {
      let index = Number(props.profile.department) - 1;
      if (index > -1) {
        setActiveDept(props.profile.departments[index]);
        if (
          props.profile.sub_departments.length > 0 &&
          props.profile.sub_department
        ) {
          setActiveSubDept(
            props.profile.sub_departments[index][
              Number(props.profile.sub_department) - 1
            ]
          );
          if (props.profile.department) {
            setSubDeptsArr(props.profile.sub_departments[index]);
          }
        }
      }
    }
  }, [props.profile]);

  useEffect(() => {
    if (
      profile.name?.length < 1 ||
      !isEmailValid ||
      (regionGroupArr && regionGroupArr.length > 0 && !regionGroup) ||
      (regionArr && regionArr.length > 1 && !activeRegion) ||
      (profile.departments &&
        profile.departments.length > 1 &&
        profile.access != "2" &&
        !activeDept) ||
      (subDeptsArr &&
        subDeptsArr.length > 0 &&
        profile.access != "2" &&
        !activeSubDept)
    ) {
      setActiveButton(false);
    } else {
      setActiveButton(true);
    }
  }, [
    profile.name,
    isEmailValid,
    activeRegion,
    regionGroup,
    activeDept,
    activeSubDept,
  ]);

  const handleEmailChange = (event) => {
    let valid = false;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    valid = emailRegex.test(String(event.target.value).toLowerCase());

    setIsEmailValid(valid);

    setProfile((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  };

  const handleNameChange = (event) => {
    let valid = false;
    const nameRegex = /^[a-zA-Z0-9\s.'-]*$/;
    valid = nameRegex.test(event.target.value);
    valid = event.target.value.length < 50 && valid;
    if (valid) {
      setProfile((prevState) => ({
        ...prevState,
        [event.target.id]: event.target.value,
      }));
    }
  };

  const handleSelectDept = (event) => {
    let dept = event;
    setActiveDept(dept);
    if (subDeptsArr) {
      let index = profile.departments.indexOf(dept);
      setActiveSubDept(undefined);
      setSubDeptsArr(profile.sub_departments[index]);
    }
  };

  const handleSelectSubDept = (event) => {
    let subdept = event;
    setActiveSubDept(subdept);
  };

  const handleSelectRegion = (event) => {
    let region = event;
    setActiveRegion(region);
  };

  const handleSelectRegionGroup = (event) => {
    let group = event;
    setActiveRegion(undefined);
    setRegionGroup(group);
    let regionFilteredArr = allRegions.filter(
      (region) => region.region_group === group
    );
    let reducedOptions = [];
    regionFilteredArr.map((region) => reducedOptions.push(region.verbose_name));
    setRegionsOptions(reducedOptions);
  };

  const saveProfile = async () => {
    if (profile.name?.length < 1) {
      openModal(t("S002"), [t("S054")]);
      return;
    }
    if (!isEmailValid) {
      openModal(t("S002"), [t("S055")]);
      return;
    }
    if (profile.departments?.length > 0 && auth.access != "2") {
      if (!activeDept) {
        openModal(t("S002"), [t("R058")]);
        return;
      }
      if (!activeSubDept && profile.sub_departments?.length > 0) {
        openModal(t("S002"), [t("R059")]);
        return;
      }
      let deptIndex = profile.departments.indexOf(activeDept);
      if (
        profile.department &&
        profile.departments[Number(profile.department) - 1] !== activeDept
      ) {
        profile.department = String(deptIndex + 1);
      } else if (!profile.department && activeDept) {
        //if departments were added after the user signed up, but now needs/can pick a department
        profile.department = String(deptIndex + 1);
      }
      if (activeSubDept) {
        profile.sub_department = String(
          profile.sub_departments[deptIndex].indexOf(activeSubDept) + 1
        );
      }
    }

    if (profile.regions.length > 0) {
      if (!activeRegion) {
        openModal(t("S002"), [t("R057")]);
        return;
      }
      let region = profile.regions.find(
        (obj) => obj.verbose_name === activeRegion
      );
      if (profile.region !== region._id) {
        profile.region = region._id;
      }
    }

    if (profile && auth.token) {
      let responseData2;
      try {
        responseData2 = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/profile`,
          "POST",
          JSON.stringify({
            profile: profile,
          })
        );
      } catch (err) {
        console.error(err);
      }
      if (responseData2) {
        showGreenCheckOverlay();
        auth.profileHandler(profile.name, profile.image);
      }
    }
  };

  return (
    <>
      {modalIsOpen && (
        <WhitePopUpNoTitle
          popupContents={modalTextContent}
          ctaBtn={t("T015")}
          handleSubmit={modalClickHandler}
        />
      )}
      {showGreenCheck && <GreenCheckOverlay />}
      {props.profile && (
        <>
          <div className="edit-field fs-exclude">
            {props.hide && (
              <div className="btn-cont ">
                <ChevronBackBtn onClick={() => props.changeMenu(0, "back")} />
              </div>
            )}
            <div className="edit-title">{t("S025")}</div>
            <div
              className={
                profile.name?.length > 0 ? "field-cont" : "field-cont error"
              }
            >
              <span className="field-descr">{t("R027")}</span>
              <input
                id="name"
                type="text"
                value={profile.name}
                onChange={handleNameChange}
              ></input>
            </div>
            {profile.name?.length < 1 && (
              <div className="error">{t("S054")}</div>
            )}
            <div className={isEmailValid ? "field-cont" : "field-cont error"}>
              <span className="field-descr">{t("R023")}</span>
              <input
                id="email"
                type="email"
                value={profile.email}
                onChange={handleEmailChange}
                disabled={
                  auth.authType != null && auth.authType !== "ChampionHealth"
                }
              ></input>
            </div>
            {!isEmailValid && <div className="error">{t("S055")}</div>}

            {regionGroupArr && regionGroupArr.length > 0 && (
              <>
                <div className="field-cont">
                  <span className="field-descr">{t("S032")}</span>
                  <div className="select-cont">
                    <CustomDropdown
                      defaultValue={"Select Region Group"}
                      options={regionGroupArr}
                      value={regionGroup}
                      onChange={handleSelectRegionGroup}
                    />
                  </div>
                </div>
                <div
                  className={activeRegion ? "field-cont" : "field-cont error"}
                >
                  <span className="field-descr">{t("S031")}</span>
                  <div className="select-cont">
                    <CustomDropdown
                      defaultValue={"Select Region"}
                      options={regionsOptions}
                      value={activeRegion}
                      onChange={handleSelectRegion}
                    />
                  </div>
                </div>
              </>
            )}
            {regionArr && regionArr.length > 1 && regionGroupArr.length < 1 && (
              <div className={activeRegion ? "field-cont" : "field-cont error"}>
                <span className="field-descr">{t("S031")}</span>
                <div className="select-cont">
                  <CustomDropdown
                    defaultValue={t("S123")}
                    options={regionArr}
                    value={activeRegion}
                    onChange={handleSelectRegion}
                  />
                </div>
              </div>
            )}
            {profile.departments &&
              profile.departments.length > 1 &&
              profile.access != "2" && (
                <div className={activeDept ? "field-cont" : "field-cont error"}>
                  <span className="field-descr">{t("S030")}</span>
                  <div className="select-cont">
                    <CustomDropdown
                      defaultValue={"Select Department"}
                      options={profile.departments}
                      value={activeDept}
                      onChange={handleSelectDept}
                    />
                  </div>
                </div>
              )}
            {subDeptsArr && subDeptsArr.length > 0 && profile.access != "2" && (
              <div
                className={activeSubDept ? "field-cont" : "field-cont error"}
              >
                <span className="field-descr">{t("S029")}</span>
                <div className="select-cont">
                  <CustomDropdown
                    defaultValue={"Select Sub-department"}
                    options={subDeptsArr}
                    value={activeSubDept}
                    onChange={handleSelectSubDept}
                  />
                </div>
              </div>
            )}
            <PinkButton1
              disabled={!activeButton}
              className="wide marginTop30"
              onClick={saveProfile}
            >
              {t("S017")}
            </PinkButton1>
          </div>
        </>
      )}
    </>
  );
};

export default AccountDetailsRightMenu;
