import React, { useEffect, useCallback } from "react";
import {
  OidcProvider,
  useOidc,
  useOidcIdToken,
  useOidcAccessToken,
  useOidcUser,
} from "@axa-fr/react-oidc";
import { useTranslation } from "react-i18next";

import { useHttpClient } from "../../../hooks/http-hook";

import microsoftLogo from "./Images/microsoft-logo.svg";
import googleLogo from "./Images/google-logo.svg";
import oktaLogo from "./Images/okta-logo.svg";
import { checkImage } from "../../../util/helperfunctions";

const Button = ({ configurationName, disabled }) => {
  const { t } = useTranslation();
  const { login } = useOidc(configurationName);

  return (
    <button
      className="grey_theme"
      onClick={() => login()}
      id={configurationName}
      disabled={disabled}
    >
      {configurationName.toLowerCase() === "microsoft" && (
        <img src={microsoftLogo} className="icon signup" alt="Microsoft Logo" />
      )}
      {configurationName.toLowerCase() === "google" && (
        <img src={googleLogo} className="icon signup" alt="Google Logo" />
      )}
      {configurationName.toLowerCase() === "okta" && (
        <img src={oktaLogo} className="icon signup" alt="Okta Logo" />
      )}
      {t("R051", { identityProvider: configurationName })}
    </button>
  );
};

const SignIn = ({ configurationName, clientId, handleResponse, disabled }) => {
  const { accessToken } = useOidcAccessToken(configurationName);
  const { idTokenPayload } = useOidcIdToken(configurationName);
  const { oidcUser } = useOidcUser(configurationName);
  const { isLoading, error, sendRequest } = useHttpClient();

  const fetchMicrosoftImage = useCallback(
    async (response, picture, token) => {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${token}`);

      const options = {
        method: "GET",
        headers: headers,
      };

      //Fetch Microsoft profile picture
      const photoBlob = await fetch(picture, options)
        .then((response) => response.blob())
        .catch((error) => console.log(error));

      const validImage = await checkImage(photoBlob);

      if (validImage) {
        const fileName = response.user?._id ?? response.userId;

        // Convert the blob to a File object
        const profileImageFile = new File([photoBlob], fileName, {
          type: photoBlob.type,
        });

        // Get Signed URL for AWS S3 upload
        const presignedUrl = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/getSignedUrl/${
            response.user?._id ?? response.userId
          }?filename=${encodeURIComponent(fileName)}`,
          "PUT"
        );

        if (presignedUrl) {
          // Upload image to AWS S3
          await sendRequest(presignedUrl.toString(), "PUT", profileImageFile, {
            "Content-Type": "image/jpeg",
          });

          let url = presignedUrl.split("?")[0];

          //Pass response and profile picture to ensure it saves on user's profile creation
          if (handleResponse) {
            // Remove all saved data from sessionStorage to ensure user has to re-authenticate
            sessionStorage.clear();
            handleResponse({ ...response, profilePic: url });
          }
        }
      }
    },
    [handleResponse, sendRequest]
  );

  const validateAuthentication = useCallback(
    async (name, email, picture, token, expiryDate) => {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/signInSSO`,
        "POST",
        JSON.stringify({
          name: name,
          email: email,
          picture: picture,
          token: token,
          expiryDate: expiryDate,
          clientId: clientId,
          auth: configurationName,
          deviceLanguage: localStorage.getItem("deviceLanguage") || "",
          platform: "web",
        })
      );

      if (
        configurationName.toLowerCase() === "microsoft" &&
        !response.user?.completed_registration
      ) {
        fetchMicrosoftImage(response, picture, token);
      } else {
        if (handleResponse) {
          // Remove all saved data from sessionStorage to ensure user has to re-authenticate
          sessionStorage.clear();
          handleResponse(response);
        }
      }
    },
    [
      clientId,
      configurationName,
      fetchMicrosoftImage,
      handleResponse,
      sendRequest,
    ]
  );

  useEffect(() => {
    if (accessToken && idTokenPayload && oidcUser) {
      validateAuthentication(
        oidcUser.given_name ?? oidcUser.name,
        oidcUser.email ?? oidcUser.preferred_username,
        oidcUser.picture,
        accessToken,
        idTokenPayload.exp
      );
    }
  }, [accessToken, idTokenPayload, oidcUser, validateAuthentication]);

  return <Button configurationName={configurationName} disabled={disabled} />;
};

function OpenIDAuthButton(props) {
  let configuration = {
    client_id: props.ssoId,
    redirect_uri: props.redirectUri,
    scope: props.scope,
    authority: props.authority,
    response_type: "code",
  };

  if (props.secret) {
    let extras = {};

    if (props.configurationName.toLowerCase() === "microsoft") {
      extras = {
        clientSecret: props.secret,
      };
    } else {
      extras = {
        client_secret: props.secret,
      };
    }

    configuration = {
      ...configuration,
      token_request_extras: extras,
    };
  }

  return (
    <OidcProvider
      configuration={configuration}
      configurationName={props.configurationName}
      loadingComponent={() => <Button {...props} disabled={true} />}
      authenticatingComponent={() => <Button {...props} disabled={true} />}
      callbackSuccessComponent={() => <Button {...props} disabled={true} />}
      sessionLostComponent={() => <Button {...props} />}
      authenticatingErrorComponent={() => <Button {...props} />}
    >
      <SignIn {...props} />
    </OidcProvider>
  );
}

export default OpenIDAuthButton;
